@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins";
  font-weight: 700;
  src: url("./assets/fonts/Poppins-Medium.ttf");
}
.colorMain {
  background: #d45d00;
}
.colorSecond {
  background: #112c56;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: auto;
  font-family: "Poppins";
  font-weight: 400;
}
.p-dialog-error {
  background-color: #f8d7da; /* Rouge clair pour les erreurs */
  color: #721c24; /* Rouge foncé pour le texte */
}

.p-dialog-success {
  background-color: #d4edda; /* Vert clair pour le succès */
  color: #155724; /* Vert foncé pour le texte */
}

label {
  font-family: "Poppins";
  font-size: 14px;
}
input[type="file"] {
  font-size: 14px;
}
